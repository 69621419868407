<template>
    <div class="body">
        <!-- banner区域 Start -->
        <div class="banner">
            <m-top-bar></m-top-bar>
        </div>
        <!-- banner区域 End -->

        <!-- switchBar区域 Start -->
        <div class="switchBar">
            <div class="switchBox">服务&维修</div>
        </div>
        <div class="line"></div>
        <!-- switchBar区域 End -->
        
        <!-- container区域 Start -->
        <div class="container">
            <div class="containerBox">
                <div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司秉承专业、周到、热情的经营理念及服务态度在为用户提供的专业规划设计中，会充分注重营收策略，充分体现“以客户为主导”的思想。特别重视前期的数据搜集整理、同行探讨、网络设计、测试以及与用户充分的交流，最大限度地保障项目的成功实施，帮助用户快速、经济地实现无线网络传输、覆盖和用户接入及相关应用，并承诺相关：
                </div>
                <div class="promise">
                    提供客户的需求沟通及方案设计<br>
                    提供工程师客户到现场环境的勘察<br>
                    提供客户的相关的样品借测<br>
                    提供客户产品技术安装培训<br>
                    提供相关产品硬件一年质保<br>
                    需要维修服务？请联系我们的售后代表<br>
                </div>
                <div class="problem">
                    遇到问题？请联系我们：
                </div>
                <div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;销售咨询:&nbsp;sales@linkom.com.cn<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;技术咨询:&nbsp;support@linkom.com.cn<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;产品支持:&nbsp;0571-85362275<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(工作时间 9:00 am- 17:30 pm)
                </div>
            </div>
        </div>
        <!-- container区域 End -->

        <!-- baseBar区域 Start -->
        <m-base-bar></m-base-bar>
        <!-- baseBar区域 End -->
    </div>
</template>

<script>
import mTopBar from '@/components/mTopBar.vue'
import mBaseBar from '@/components/mBaseBar.vue'

export default {
    name: 'Support',
    components: {
        mTopBar,
        mBaseBar,
    }
}
</script>

<style scoped>
.body {
    background-color: #f7f7f7 !important;
}

/* banner区域 */
.banner {
    width: 100%;
    min-height: 2050px;
    background: url(../../../assets/mobile/img/support/banner.jpg) center no-repeat;
    background-size: 230%;
}

/* switchBar区域 */
.switchBar {
    padding-top: 50px;
    width: 100%;
    height: 300px;
    text-align: center;
    background-color: #ffffff;
}

.switchBar .switchBox {
    margin: 0 auto;
    text-align: center;
    width: 700px;
    height: 200px;
    font-size: 82px;
    line-height: 200px;
    color: #ffffff;
    border-radius: 30px;
    background-color: #c3a35d;
}

.line {
    margin: 0 auto;
    width: 90%;
    border-bottom: 1px solid #c3a35d;
}

/* container区域 */
.container {
    padding: 50px;
    width: 100%;
    min-height: 500px;
}

.container .containerBox {
    padding: 100px;
    width: 100%;
    min-height: 300px;
    font-size: 70px;
    text-align: justify;
    background-color: #ffffff;
}

.container .containerBox .promise {
    margin-top: 100px;
    margin-bottom: 100px;
    font-weight: 700;
}

.container .containerBox .problem {
    margin-bottom: 50px;
}
</style>